.container {
  position: absolute;
  top: 50px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
}

.content {
  padding: 16px;
  z-index: 2;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.sidebar {
  display: none;
  z-index: 2;
}

@media only screen and (max-width: 768px) {
  .desktop {
    display: none !important;
  }

  .container {
    bottom: 52px;
  }
}

@media only screen and (min-width: 767px) {
  .mobile {
    display: none !important;
  }

  .expand {
    position: absolute;
    top: 50px;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;

    display: grid;
    grid-template-columns: 35rem 1fr;
  }

  .collapse {
    position: absolute;
    top: 50px;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;

    display: grid;
    grid-template-columns: 4rem 1fr;
  }

  .expand > .content {
    position: fixed;
    top: 50px;
    left: 15rem;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  .expand > .sidebar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    /* right: 15rem; */
    bottom: 0;
  }

  .expand > .sidebar > div {
    width: 15rem;
  }

  .collapse > .content {
    position: fixed;
    top: 50px;
    left: 4rem;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  .collapse > .sidebar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    /* right: 4rem; */
    bottom: 0;
  }

  .collapse > .sidebar > div {
    width: 4rem;
  }
}
