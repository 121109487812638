.logo {
  margin-right: 8px;
}

.expand {
  width: 15rem;
  padding-left: 6.4px;
}

.collapse {
  width: 4rem;
  padding-left: 4.59px;
}

.title {
  margin-left: -16px;
  padding-left: 16px;
  display: flex;
  width: 100%;
}

.title:hover {
  text-decoration: none;
}

.title:hover h5 {
  color: #215DB0;
}

.navbar {
  background-color: #d6890c;
  color: #ffffff;
}
