@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~swagger-ui-react/swagger-ui.css";

body {
  background-color: #F5F8FA;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.dialog-title {
  color: #8A9BA8;
  text-transform: uppercase;
  font-size: 12px !important;
}

.dialog-title:not(:first-of-type) {
  margin-top: 20px;
}

.dialog-form,
.form {
  display: grid;
  grid-template-columns: 160px 1fr;
}

.form-2 {
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.bp4-tab-indicator-wrapper .bp4-tab-indicator {
  background-color: #FFC940 !important;
}

*::-webkit-scrollbar {
  width: .5em;
  height: .5em;
}

*::-webkit-scrollbar-track {
  background-color: #A7B6C233;
}

*::-webkit-scrollbar-thumb {
  background-color: #5C708069;
}