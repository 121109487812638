.root {
  /* background-color: #fff; */
  background-color: #0d2733;
  bottom: 0;
  padding-top: 0px;
  padding-bottom: 16px;
  padding-left: 0px;
  padding-right: 0px;
  position: absolute;
  top: 0px;
  width: 256px;
}

.logo {
  margin-right: 8px;
}

.logo2 {
  margin-left: -20px;
}

.topSidebar {
  margin-left: 30px;
  margin-top: 20px;
}

.menuSide {
  flex-shrink: 0;
  height: 80vh;
  overflow: auto;
  position: sticky;
  top: 0;
}

.topSecondSidebar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 7px;
}

.arrow > a {
  padding-top: 14px;
  padding-bottom: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
}

.arrow > a > span:first-of-type {
  text-align: left;
  padding-left: 6.4px;
}

.menu > a {
  border-radius: unset;
  min-height: 48px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.menu > a > span:first-of-type {
  text-align: left;
  padding-left: 6.4px; /*Default padding set 10px to point ICON*/
  padding-right: 11px;
}

.menu > a {
  margin-bottom: 8px;
}

.line {
  width: 100%;
  border-bottom: 1px solid #535b67;
  margin: auto;
  padding-top: 28px;
  margin-bottom: 22px;
}
